<template>
  <div class="steps-to-take-section">
    <div class="container">
      <h2 class="header">
        Getting connected with a local contractor<br />
        is as easy as 1..2..3
      </h2>
      <div class="fade-line"></div>
      <p class="text">
        Just take our quick survey & find out if you are eligible for solar savings while also calculating how much you can save on your electricity bills!
      </p>
      <div class="step-container">
        <div class="box">
          <img src="../../assets/images/s2-icon1.png" alt="home-location">
          <img src="../../assets/images/s2-arw.png" alt="arrow" class="arrow">
          <h3>Enter in your zip code</h3>
          <p>We will find the best local home pros in your area</p>
        </div>
        <div class="box">
          <img src="../../assets/images/s2-icon2.png" alt="home-with-solar-panels">
          <img src="../../assets/images/s2-arw.png" alt="arrow" class="arrow">
          <h3>Check Eligibility</h3>
          <p>Enter basic information to see if your home improvement project qualifies</p>
        </div>
        <div class="box">
          <img src="../../assets/images/s2-icon3.png" alt="hand-with-coin">
          <h3>Start your Project, while saving money!</h3>
          <p>Once your project is approved, you will be connected with your home pro immediately!</p>
        </div>
      </div>
      <form>
        <button formaction="/solar-savings">Calculate Savings</button>
      </form>
      <h2 class="header">
        Why Choose Us <br />
        Your Home Improvement Partner
      </h2>
      <div class="fade-line"></div>
      <p class="text">
        At <a href="#">openhomepros.com</a>, we work with leading home improvement experts to help get you the best pricing and the best service!
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/style/main';
  .steps-to-take-section {
    font-family: "Poppins", sans-serif;
    background-color: $dark-blue;
    padding: 60px 0 70px;
    .container {
      .header {
        color: white;
        font-size: 40px;
        line-height: 50px;
        font-weight: 600;
        text-align: center;
        padding-bottom: 22px;
      }
      .fade-line {
        height: 1px;
        background-color: #E6E6E6;
        width: 80%;
        margin: 0 auto;
        background-image: linear-gradient(to left , $dark-blue 2%, white 50%, $dark-blue 98%);
        background-image: -o-linear-gradient(left , $dark-blue 2%, white 50%, $dark-blue 98%);
        background-image: -moz-linear-gradient(left , $dark-blue 2%, white 50%, $dark-blue 98%);
        background-image: -webkit-linear-gradient(left , $dark-blue 2%, white 50%, $dark-blue 98%);
        background-image: -ms-linear-gradient(left , $dark-blue 2%, white 50%, $dark-blue 98%);
        background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0.02, $dark-blue), color-stop(0.5, white), color-stop(0.98, $dark-blue) );
      }
      .text {
        color: white;
        font-size: 19px;
        margin-top: 30px;
        letter-spacing: .5px;
        line-height: 28px;
        padding: 0 150px;
        text-align: center;
        a {
          color: white;
          text-decoration: none;
        }
      }
      .step-container {
        display: flex;
        overflow: hidden;
        margin-top: 40px;
        .box {
          width: 33.333%;
          text-align: center;
          padding: 20px 10px 25px;
          border: 1px solid #e9e9e9;
          border-radius: 10px;
          position: relative;
          h3 {
            color: #1e1e1e;
            font-size: 24px;
            font-weight: 700;
            margin-top: 10px;
          }
          p {
            color: #1e1e1e;
            font-size: 19px;
            line-height: 27px;
            margin-top: 10px;
            padding: 0 20px;
          }
          .arrow {
            position: absolute;
            right: -20px;
            top: 115px;
            z-index: 1;
          }
          &:nth-child(1) {
            background: #e7fff8;
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          &:nth-child(2) {
            background: #fffbe7;
            border-left: 1px solid #e9e9e9;
            border-right: 1px solid #e9e9e9;
            border-radius: 0;
          }
          &:nth-child(3) {
            background: #e7f8ff;
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
      form {
        display: flex;
        justify-content: center;
        button {
          background: -moz-linear-gradient(180deg, #ff001a, #911f2b);
          background: -webkit-linear-gradient(180deg, #ff001a, #911f2b);
          background: linear-gradient(180deg, #ff001a, #911f2b);
          border: none;
          font-size: 25px;
          font-weight: 500;
          border-radius: 5px;
          font-family: $font-poppins;
          color: white;
          outline: none;
          text-decoration: none;
          padding: 10px 20px;
          margin: 50px 0;
          position: relative;
          cursor: pointer;
          &:hover {
            background: -moz-linear-gradient(0deg, #ff001a, #911f2b);
            background: -webkit-linear-gradient(0deg, #ff001a, #911f2b);
            background: linear-gradient(0deg, #ff001a, #911f2b);
          }
        }
      }
    }
  }

@media only screen and (max-width: 1180px) {
  .steps-to-take-section  {
    .container {
      .step-container {
        .box {
          h3 {
            font-size: 22px;
          }
          p {
            font-size: 17px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1021px) {
  .steps-to-take-section  {
    .container {
      .header {
        font-size: 34px;
        line-height: 42px;
      }
      .text {
        padding: 0;
        margin-top: 20px;
      }
      .step-container {
        .box {
          padding: 20px 15px 25px;
          .arrow {
            right: -15px;
            top: 140px;
            height: 30px;
          }
          h3 {
            font-size: 21px;
          }
          p {
            padding: 0 5px;
            font-size: 17px;
            line-height: 25px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .steps-to-take-section  {
    padding: 30px 0 30px;
    .container {
      .header {
        font-size: 27px;
        line-height: 36px;
        background-size: 400px;
      }
      .text {
        font-size: 17px;
        line-height: 25px;
        padding: 0 15px;
      }
      .step-container {
        flex-direction: column;
        .box {
          padding: 20px 10px 35px;
          width: 100%;
          .arrow {
            left: 50%;
            margin-left: -15px;
            right: auto;
            bottom: -15px;
            top: auto;
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
          }
          &:nth-child(1) {
            border-right: 1px solid #e9e9e9;
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-top-right-radius: 10px;
          }
          &:nth-child(2) {
            border-left: 1px solid #e9e9e9;
            border-right: 1px solid #e9e9e9;
            border-radius: 0;
          }
          &:nth-child(3) {
            border-top: none;
            border-left: 1px solid #e9e9e9;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 10px;
          }
        }
      }
      form button {
        margin: 40px 0;
      }
    }
  }
}

@media only screen and (max-width: 430px) {
  .steps-to-take-section  {
    .container {
      form button {
        font-size: 23px;
      }
    }
  }
}
</style>