<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const topStripText = computed(() => {
  return route.path.includes('/roofing')
      ? 'Get approved for roofing deals in your area!'
      : 'Find top pros in your area for any project, and improve your home today';
});
</script>

<template>
  <div class="top-strip">
    <div class="container">
      <div id="top-strip-text">{{ topStripText }}</div>
    </div>
  </div>
  <div class="header">
    <div class="container">
      <a href="/">
        <img src="../../assets/images/OpenHomeSmallLogo.png" alt="Open-Home-Logo">
      </a>
      <a href="tel:+18779204506" class="button">(877) 920-4506</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '../../assets/style/main';

  .top-strip {
    min-width: 100%;
    background: $dark-blue;
    #top-strip-text {
      font-family: $font-poppins;
      color: white;
      font-size: 17px;
      line-height: 19px;
      letter-spacing: .5px;
      padding: 8px 0;
      font-weight: 400;
      text-align: center;
    }
  }
  .header {
    min-width: 100%;
    background: white;
    padding: 8px 0;
    img {
      max-width: 216px;
    }
    .button {
      background: -moz-linear-gradient(180deg, #ff001a, #911f2b);
      background: -webkit-linear-gradient(180deg, #ff001a, #911f2b);
      background: linear-gradient(180deg, #ff001a, #911f2b);
      border: none;
      font-size: 17px;
      font-weight: 500;
      border-radius: 5px;
      margin-top: 17px;
      font-family: $font-poppins;
      color: white;
      outline: none;
      text-decoration: none;
      padding: 10px 20px;
      float: right;
      &:hover {
        background: -moz-linear-gradient(0deg, #ff001a, #911f2b);
        background: -webkit-linear-gradient(0deg, #ff001a, #911f2b);
        background: linear-gradient(0deg, #ff001a, #911f2b);
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .top-strip #top-strip-text {
      font-size: 15px;
      width: 100%;
      white-space: nowrap;
      -moz-animation: top-strip-text 15s linear 0s infinite;
      -webkit-animation: top-strip-text 15s linear 0s infinite;
    }
    .header {
      img {
        max-width: 154px;
      }
      .button {
        margin-top: 6px;
      }
    }
  }

  @media only screen and (max-width: 400px) {
    .header .button {
      padding: 6px 10px;
      max-width: 109px;
      text-align: center;
      line-height: 1.2;
      margin-top: 3px;
    }
  }

  @media only screen and (max-width: 300px) {
    .header .button {
      font-size: 14px;
      max-width: 92px;
      margin-top: 6px;
    }
  }

  @keyframes top-strip-text {
    0% {
      margin-left: 100%;
    }

    100% {
      margin-left: -600px;
    }
  }
</style>