import { createStore } from 'vuex'
import {
  getIpAddress,
  getLeadSource,
  getPageReferrer,
  getUserAgent,
  getJornayaLeadIdToken,
  getTrustedFormByPromise,
  extractAllUrlParamsAndSetThemInVuexStore
} from '@/js/utils'
import solarFlow from '@/store/modules/solar-flow'
import roofingFlow from '@/store/modules/roofing-flow'
import windowsFlow from '@/store/modules/windows-flow'
import remodelFlow from '@/store/modules/remodel-flow'
import flooringFlow from '@/store/modules/flooring-flow'
import twilioSmsVerify from '@/store/modules/twilio-sms-verify'
import { FACTS_LIST } from '@/js/constants'

export default createStore({
  state: {
    steps: {},
    fields: {},
    currentStep: null,
    isLoading: false,
    tempFactsList: [],
    visitedFlows: {
      solar: false,
      windows: false,
      roofing: false,
      remodel: false,
      flooring: false
    },
    currentFlow: null,
    phoneError: false,
    progress: 0,
    minHeightOfTheLastComponent: 0,
    tempEverFlowId: null
  },
  mutations: {
    SET_CURRENT_STEP( state, step ) {
      state.currentStep = step
    },
    SET_CURRENT_FLOW(state, flow) {
      state.currentFlow = flow
    },
    SET_FIELD(state, { field, value }) {
      state.fields[field] = value
    },
    SET_ARRAY_OF_FIELDS(state, array) {
      array.forEach(object => {
        state.fields[object.field] = object.value
      })
    },
    SET_TEMP_EF_ID(state, value) {
      state.tempEverFlowId = value
    },
    SET_CAMPAIGN(state, { campaignId, supplierId, key }) {
      state.fields.lp_campaign_id = campaignId
      state.fields.lp_supplier_id = supplierId
      state.fields.lp_key = key
    },
    SET_IS_LOADING(state, status) {
      state.isLoading = status
    },
    SET_TEMP_FACTS_LIST(state, array) {
      array.forEach(arrElement => {
        state.tempFactsList.push(arrElement)
      })
    },
    DELETE_FIRST_ELEMENT_OF_LIST(state) {
      state.tempFactsList.shift();
    },
    SET_PHONE_ERROR(state, value) {
      state.phoneError = value
    },
    SET_VISITED_FLOW(state, flow) {
      state.visitedFlows[flow] = true
    },
    SET_ALL_FLOWS_AS_VISITED(state) {
      Object.keys(state.visitedFlows).forEach((key) => {
        state.visitedFlows[key] = true
      })
    },
    SET_PROGRESS_BASED_ON_NUMBER_OF_CASES(state, steps) {
      // Here we check if we are in the next flows
      let count = 0
      Object.values(state.visitedFlows).forEach(visited => {
        if(visited) count += 1
      })
      //If the lead goes through the first flow the next flows will not show the PII so we need to remove some steps from progress bar
      if (count > 1) steps = steps - 4

      state.progress += Math.floor(100 / steps)
    },
    SET_PROGRESS_BAR_TO_DEFAULT(state) {
      state.progress = 0
    },
    SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT(state, value) {
      state.minHeightOfTheLastComponent = value
    },
    SET_ORIGIN_PATH(state) {
      if (Object.values(state.visitedFlows).filter(a => a === true).length === 1) {
        state.fields.origin_path = Object.keys(state.visitedFlows).find(key => state.visitedFlows[key] === true)
      }
    }
  },
  actions: {
    init({commit}) {
      //get ip address and set as field in store
      getIpAddress()
          .then((ip) => {
            commit('SET_FIELD', { field: 'ip_address', value: ip })
          })

      // Get Jornaya LeadId
      getJornayaLeadIdToken()
          .then(leadid_token => {
            commit('SET_FIELD', { field: 'jornaya_id', value: leadid_token})
          })

      // Get Trusted Form
      getTrustedFormByPromise()
          .then(({ trustedFormId, trustedFormUrl }) => {
            const trustedForm = [
              { field: 'trusted_form_cert_id', value: trustedFormId },
              { field: 'trusted_form_cert_url', value: trustedFormUrl }
            ]
            commit('SET_ARRAY_OF_FIELDS', trustedForm)
          })

      //extract all URL params and set them as fields in Vuex Store
      //lp_campaign_id, lp_supplier_id, and lp_key will be overwritten here if found in URL
      commit('SET_ARRAY_OF_FIELDS', extractAllUrlParamsAndSetThemInVuexStore())

      //get user agent
      try {
        commit('SET_FIELD', { field: 'user_agent', value: getUserAgent() })
      } catch (e) {
        console.log('error getting user agent', e)
      }

      //get page referrer
      commit('SET_FIELD', { field: 'page_referer', value: getPageReferrer() })

      //get lead source: facebook / google
      commit('SET_FIELD', { field: 'lp_s2', value: getLeadSource() })
    },
    showNextFact({state, commit}) {
      state.tempFactsList !== 0 ? commit('DELETE_FIRST_ELEMENT_OF_LIST') : commit('SET_TEMP_FACTS_LIST', FACTS_LIST)
    },
    goToNextStep({state, dispatch}, stepName) {
      dispatch(`${state.currentFlow}Flow/goToNextStep`, stepName)
    },
    goToAnotherFlow({state, dispatch}, flow) {
      dispatch(`${flow}Flow/goToNextStep`, 'StartPath')
    },
    tapStoneLinkOut({state}) {
      location.href = `https://click.clktraker.com/aff_ad?campaign_id=8156&aff_id=10257&hostNameId=2951&aff_sub=OHpop&aff_click_id=${state.tempEverFlowId}&aff_sub2=${state.fields.affid || '1'}&aff_sub3=${state.fields.sub_id || ''}`
    }
  },
  modules: {
    solarFlow,
    roofingFlow,
    windowsFlow,
    remodelFlow,
    flooringFlow,
    twilioSmsVerify
  }
})
